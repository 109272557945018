import React from 'react'
import Logo from '../../assets/logo.svg'

import('./style.scss')
export default function Splash() {
  return (
    <div className="splash">
      <header />
      <main>
        <Logo style={{ marginBottom: 15, width: 64, height: 64 }} />
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          hi <span style={{ opacity: 0.5 }}>{`{at}`}</span> insio.io
        </div>
      </main>
      <footer />
    </div>
  )
}
