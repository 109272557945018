import React from 'react'
import SEO from '../components/seo'
import Splash from '../components/Splash'

import('./index.scss')
const IndexPage = () => (
  <>
    <SEO title="Coming soon" />
    <Splash />
  </>
)

export default IndexPage
